<template>
  <b-modal
      id="bay-online-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ Krungsri Online"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
              v-slot="{ errors }"
              name="username"
              rules="required"
          >
            <b-form-group label="Username">
              <b-form-input
                  v-model="form.username"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  name="username"
                  placeholder="ยูสเซอร์เนม"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
              v-slot="{ errors }"
              name="password"
              rules="required"
          >
            <b-form-group label="Password">
              <b-form-input
                  v-model="form.password"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  name="password"
                  placeholder="รหัสผ่าน"
                  type="password"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="d-flex">
            <b-button :disabled="isFetching" class="ml-auto" type="submit" variant="primary">
              ลงทะเบียน
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'BayOnlineModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    bayAccount: {
      type: Object,
      default: () => ({
        username: '',
        password: '',
      })
    }
  },
  data() {
    return {
      isFetching: false,
      form: {
        username: '',
        password: ''
      }
    }
  },
  created() {
    this.form = {
      username: this.bayAccount?.username || '',
      password: this.bayAccount?.password || '',
    }
  },
  methods: {
    ...mapActions(['registerBayOnline', 'fetchBankAccount']),
    async onSubmit() {
      this.isFetching = true
      const response = await this.registerBayOnline({ id: this.agentBankAccountId, data: this.form })
      if(response) {
        this.$bvModal.hide('bay-online-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
  }
})
</script>

<style scoped>

</style>